/* aboutpage.css */
.content-container 
{
  height: 100vh;
  width: 100%;
  margin: 150px auto;
  box-sizing: border-box;
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
}

.about-content {
  display: flex;
  font-family: 'Lato';
  gap: 20px;
  align-items: flex-start;
  height: calc(100vh - 200px);
  justify-content: space-between;
  width: 60%;
}
.about-image 
{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.about-image img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.about-text 
{
  height: 100%;
  overflow: auto;
  max-height: 500px;
  width: 70%;
}

.about-text p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: 'Lato';
}
.about-text .link 
{
  color: white;
}

@media screen and (max-width: 759px) {
  .content-container {
    box-sizing: border-box;
    width: 100%;
    align-items: flex-start;
    height: max-content;
    margin-top: 30px;
    padding-bottom: 60px;
  }
  .about-content
  {
    flex-direction: column;
    gap: 10px;
    height: auto;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
  }
  .about-image {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    
  }
  .about-image img 
  {
    object-fit: contain;
    width: 50%;
    aspect-ratio: 1 / 1;
    height: auto;
  }
  .blog-title {
    margin-left: 0;
  }
  .about-text p{
    font-size: 1.2rem;
  }
  .about-text {
    padding-right: 0;
    padding: 0; 
    width: 100%;
    overflow: visible;
    max-height: 100%;
  }
}
