
header 
{
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  top: 0;
  z-index: 100;
}
header a 
{
  text-decoration: none;
  color: initial;
  transition: 200ms ease-in-out;
  color: inherit;
}
header a:hover 
{
  text-decoration: underline;
}
header h1 {
  font-weight:bolder;
  font-size: 80px;
  font-family: "JetBrains Mono", monospace;
  margin: 0; 
  text-transform: uppercase;
  text-align: center;
  color: inherit;
}


@media screen and (max-width: 750px) {

  header h1 {
    font-size: 36px;
  }
}