
.image  img{
    max-width: 100%; 
    width:100%;
}

h1{
    font-size: 57px;
    font-family: 'slabo';
}
.text{
    display: flex;
}
.left-text {
    flex: 1;
}

.right-text{
    margin-left: 50px;
    font-size: 26px;

}

.image2{
    margin-top: 8rem;
}

.image2  img{
    max-width: 100%; 
    width:100%;
}

.image3{
    margin-top: 1rem;
}

.image3  img{
    max-width: 100%; 
    width:100%;
}

/* responsive starts here */
@media screen and (max-width: 759px) {
    .text 
    {
        flex-direction: column;
    }
    .right-text 
    {
        margin-left: 0;
        margin-top: 0;
    }
    .margin-top-40
    {
        margin-top:40%;
    }
}