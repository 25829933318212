.grid 
{
    display: grid;
    gap: 10px;
    padding: 10px;
    width: 50%;
    margin: auto;
    box-sizing: border-box;
}
.grid img 
{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.grid-2-col {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.grid-2-col img 
{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 750px){
    .grid 
    {
        width: 100%;
        padding: 0;
    }
}
