@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
@import url('https://fonts.google.com/specimen/Lato?query=lato');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap');
/* font-family: 'Slabo 27px', serif; 
font-family: "JetBrains Mono", monospace;
font-family: "Sofia Sans", sans-serif;
font-family: "Lato", sans-serif; */

.App {
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* common styling starts herer */
/* to be display only on mobile */
.mobile 
{
    display: none;
}
/* to only visible on desktop */
.desktop 
{
    display: block;
}
.full-width 
{
    width: 100%;
}
.text-center 
{
    text-align: center;
}
.link 
{
  color: black;
}
.link:hover 
{
  color: #4EC5EA;
}


.project-2-cols 
{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    max-width: 100%;
}
.project-2-cols .left , .project-2-cols .right 
{
    width: 100%;

}
.project-2-cols img 
{
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: cover;
}
.square-image 
{
    height: fit-content;
    margin: 0 auto;
    width: 100%;
}
.square-image .splide 
{
    height: fit-content;
    aspect-ratio: 1/1;
    overflow: hidden;
    box-sizing: border-box;
}

.project-2-cols .splide__slide img
{
    aspect-ratio: 3/4;
    object-fit: contain;
} 

.square-image .splide__slide img , .square-image .splide__track , .square-image .splide__slide , .square-image img 
{
    aspect-ratio: 1/1;
    overflow: hidden;
}

.size-4-3 img
{
    aspect-ratio: 4/3;
}


@media screen and (max-width: 759px) {
  .mobile 
    {
        display: block;
    }
    .desktop 
    {
        display: none;
    }
    .margin-top-20
    {
        margin-top:30%;
    }
    .padding-top-20
    {
        padding-top:25%;
    }
    .project-2-cols 
    {
      gap: 5px;
    }
  }