.project-overall 
{
    height: auto;
    max-width: 1200px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.project-overall .navigation 
{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    font-size: 40px;
    color: black;
}
.project-overall .navigation a 
{
    text-decoration: none;
    color: black;
    cursor: pointer;
    position: fixed;
    top: 50%;

}
.project-overall .navigation a:first-child 
{
    left: 5%;
}
.project-overall .navigation a:last-child 
{
    right: 5%;
}
.blog .navigation a 
{
    color: white
}

*p 
{
  margin: 0;
}
h1,h2,h3,h4 
{
   margin: 0;
}


.image , .image-large{
    display: flex;
    align-items: center;
    justify-content: center;
}

.image  img{
    max-width: 100%; 
    object-fit: cover;
    max-height: 500px;
}

.image-large img 
{
    width: 100%;
    max-height: 750px;
} 

.image .splide__slide {
    backface-visibility: hidden;
    box-sizing: border-box;
    flex-shrink: 0;
    list-style-type: none !important;
    margin: 0;
    position: relative;
    justify-content: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.image .splide__slide img 
{
    height: 600px;
    object-fit: contain;
    width: auto;
}

.project-overall .text{
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-style: normal;
    gap: 20px;
    box-sizing: border-box;
}
.project-overall .text .title 
{
    display: flex;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    text-transform: uppercase;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 50px;
}
.project-overall .title h1 
{
    width: 40%;
    font-family: "JetBrains Mono", monospace;
    font-size: 45px;
}
.project-overall .description 
{
    width: 60%;
}
.project-overall h2 
{
    font-family: "JetBrains Mono", monospace;
    font-size: 22px;
    letter-spacing: 1px;
}
.project-overall .description p 
{
    color:#848484;
    font-family: "JetBrains Mono", monospace;
    font-size: 20px;
    padding: 0;
    margin: 0;
}
.left-text {
    flex: 1;
    width: 50%;
    font-family: 'Slabo 27px', serif; 
    box-sizing: border-box;
}

.project-overall .right-text{
    margin: 0;
    width: 100%;    
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.project-overall .right-text p 
{
    width: 60%;
    font-family: "Sofia Sans", sans-serif;
    font-size: 22px;
}
.right-text .text-center 
{
    width: 100%;
}

.image2{
    margin-top: 1rem;
}

.image2  img{
    max-width: 100%; 
    width:100%;
}
.image3 
{
    width: 70%;
    margin: auto;
}

.image3  img{
    max-width: 100%; 
    width:100%;
}

/* BLog Page */
.blog p 
{
    width: 50%;
}
.blog
.link {
    color: white;
    position: relative;
    z-index: 1000;
}
/* custom classes */

.width-full
{
    width: 100% !important;
}
.width-half-desktop
{
    width: 50% !important;
}
.text-center 
{
    text-align: center !important;
    width: 100% !important;
}
.flex-column 
{
    flex-direction: column !important;
}


/* responsive starts here */
@media screen and (max-width: 750px) {

    .project-overall 
    {
        padding: 20px;
        margin-top: 50px;
    }
    .project-overall .navigation 
    {
        top: auto;
        bottom: 100px;
        padding: 0 20px;
        position: static;
        transform: none;
    }
    .project-overall .navigation a 
    {
        position: static;
    }
    .project-overall .text .title 
    {
        text-align: center;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .project-overall .title h1 
    {
        width: 100%;
        font-size: 40px;
    }
    .project-overall .description 
    {
        width: 100%;
        font-size: 20px;
    }
    .project-overall .description h2 
    {
        font-size: 28px;
    }
    .project-overall .description p 
    {
        margin: 0 auto;
        width: 100%;
        font-size: 20px;
    }
    .project-overall .right-text p 
    {
        width: 100%;
        font-size: 17px;
        text-align: left;
        margin: 0;
    }
    
    .text 
    {
        flex-direction: column;
    }
    .left-text 
    {
        width: 100%;
        box-sizing: border-box;
    }
    .left-text h1 
    {
        font-size: 2rem;
    }
    .right-text 
    {
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        font-size: 18px;
        text-align: justify;
    }
    .left-text 
    {
        width: 100%;
    }
    .image .splide__slide img 
    {
        height: 300px;

    }
    .width-full-mobile 
    {
        width: 100% !important;
    }
    
}