footer 
{
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 60px;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
}
footer a {
  text-decoration: none;
  font-family: "JetBrains Mono", monospace;
  font-size: 60px;
  color: inherit;
}
footer a:hover 
{
    text-decoration:underline;
}

@media screen and (max-width: 750px) { 
  footer 
  {
    padding: 0 20px;
    justify-content: space-between;
    gap: 5px;
  }
  footer a 
  {
    font-size: 32px;
  }
}