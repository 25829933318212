*p
{
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}
h1,h2,h3,h4 
{
    font-family: 'Slabo 27px', serif; 
    width: 100%;
    box-sizing: border-box;
    word-wrap: normal;
    line-break: auto;
}
.list-text
{
    font-family: "Lato", sans-serif;
    font-size: 26px;
}
.image  img{
    max-width: 100%; 
    width:100%;
}
.text{
    display: flex;
}
.left-text {
    flex: 1;
}

.right-text{
    margin-left: 50px;
    font-size: inherit;
}

.image2{
    margin-top: 5rem;
}

.image2  img{
    max-width: 100%; 
    width:100%;
}

.image3{
    margin-top: 5rem;
}

.image3  img{
    max-width: 100%; 
    width:100%;
}

.image2 .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
  }

@media screen and (max-width: 759px) {
    .margin-top-20
    {
        margin-top:20%;
    }
}