/* homepage.css */
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
.overall 
{
    height: 90vh; 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.home--container 
{

    height:100vh;
    box-sizing: border-box;
}

.home--contents {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    position: relative;
}

.home--contents .bg-img {
    width: 80%;
    height: 500px;
    object-fit: cover;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home--container_links {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: 500px;
    position: relative;
    overflow: auto;
    z-index: 1;
}
.bloglist-overall .home--container_links 
{
    max-height:400px;
}
.home--container_links .bg-img 
{
    position: absolute;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.bloglist-overall .home--container_links .bg-img 
{
    height: 400px;
}

.home--container_link a 
{
    font-family: "JetBrains Mono", monospace;
    font-size: 30px;
    font-weight: 400;
    line-height: 48.89px;
    text-align: left;
    text-decoration: none;
    color: black;
    transition: 100ms ease-in-out;
    text-transform: uppercase;
}

.home--container_links .active a , .home--container_link a:hover
{
    color: white;
    background-color: black;
    text-decoration: underline;
}


.home--container_slider 
{
    width: 40%;
    height: fit-content;
    margin-bottom: 10px;
}

.home--container_slider  .splide 
{
    display: flex;
    flex-direction: column;
}

.home--container_slider  .splide__slide
{
    overflow: hidden;
    display: flex;
    flex-direction: column;
   
}
.home--container_slider  .splide__slide .year 
{
    font-size: 24px;
    font-family: Slabo 27px;
}

.home--container_slider img
{
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    border-radius: 25px;
    object-fit: cover;
    overflow: hidden;
    background-color: white;
}
.home--container_mobile
{
    display: none;
}

/* blog list page */
.bloglist-links a
{
    color: white;
}
.bloglist-links a:hover 
{
    background-color: white;
    color: black;
    text-decoration: underline;
}

.bloglist-text 
{
    width: 50%;
    font-size: 24px;
    font-weight: 100;
    font-style: italic;
    font-family: "JetBrains Mono", monospace;
    text-transform: uppercase;
    margin-top: 80px;
}
@media screen and (max-width: 759px) {
    .desktop 
    {
        display: none;
    }
    body 
    {
        cursor:pointer;
    }
    .overall 
    {
        padding: 20px;
        margin-bottom: 80px;
    }
    .home--container_desktop 
    {
        display: none;
    }
    .home--container_mobile {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        padding-bottom: 80px;
        overflow: hidden;
        gap: 20px;
    }
    .home--container_mobile
    {
        display: flex;
        flex-direction: column;
        font-family: 'Slabo 27px', serif;
    }
    .home--container_box {
        overflow: hidden;
        width: 100%;
        aspect-ratio: 4 / 3;
        position: relative;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 3px;
        
    }
    .home--container-m_title 
    {
        font-size: 20px;
        color: white;
        text-align: center;
        font-family: "JetBrains Mono", monospace;
        text-transform: uppercase;
    }
    .home--container_box h2 
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        text-transform: uppercase;
        color: white;
    }
    .home--container .year 
    {
        z-index: 1;
        color: white;
        text-decoration: none;
        position: absolute;
        top:20px;
        left: 20px;
        font-size: 18px;    
    }
    .home--container_box img 
    {
        width: 100%;
        height: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit: cover;
        background-color: white;
        z-index: -1;

    }
    .home--container_links{
        justify-content: center;
        gap: 10px;
        height: 100%;
        overflow:hidden;
    }
    .bloglist-text 
    {
        font-size: 20px;
        margin-top: 100px;
    }
    .bloglist-links a 
    {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}